<template>
  <b-card>
    <b-row>
      <b-col cols="12">
        <h1>
          {{ venda.produto }} {{ venda.quadra }}-{{ venda.lote }}
          <small class="m-1">{{ venda.clientId }}</small>
        </h1>
      </b-col>
    </b-row>
    <b-row>
        <b-col cols="12">
            <b-badge variant="danger" class="ml-1 mt-1">
                Cliente Quente
            </b-badge>
            <b-badge variant="danger" class="ml-1 mt-1">
                Cliente Quente
            </b-badge>
        </b-col>
    </b-row>
    <b-row>
        <b-col md="6" class="mt-2">
            <h4 class="ml-1">Cliente: <small>Funala</small></h4>
        </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BBadge } from "bootstrap-vue";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BBadge
  },
  props: {
    venda: String,
  },
};
</script>

<style>
</style>