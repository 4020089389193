<template>
  <b-tabs pills>
    <b-tab active>
      <template #title>
        <feather-icon icon="ListIcon" size="16" class="mr-0 mr-sm-50" />
        <span class="d-none d-sm-inline"> Informações</span>
      </template>
      <div>
        <Venda-status :venda="venda" />
      </div>
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from "bootstrap-vue";

import VendaStatus from "./components/VendaStatus.vue";
export default {
  components: { BTabs, BTab, VendaStatus },
  data() {
    return {
      venda: {},
    };
  },
  methods: {
    getVenda() {
      this.venda = {
        id: "123",
        produto: "BSB1",
        quadra: "R",
        lote: "09",
        clientId: "#20292",
        imob: "Alpha 300",
      };
    },
  },
  mounted() {
    this.getVenda();
  },
};
</script>

<style>
</style>